<template>
  <div class="home_warp">
    <div class="left_controller">

      <div class="item"
           @click="toPage('/index')">
        <img src="@/assets/home/首页.png"
             v-if="path != '/index'"
             alt="">
        <img src="@/assets/home/首页_active.png"
             v-else
             alt="">
        <span :class="{'active':path == '/index'}">首页</span>
      </div>
      <div class="item"
           @click="toPage('/errorbasis')">
        <img src="@/assets/home/错题本.png"
             v-if="path != '/errorbasis'"
             alt="">
        <img src="@/assets/home/错题本_active.png"
             v-else
             alt="">
        <span :class="{'active':path == '/errorbasis'}">
          错题本
        </span>
      </div>
      <div class="item"
           @click="toPage('/analyze_report_wrap')">
        <img src="@/assets/home/分析报告.png"
             v-if="path != '/analyze_report_wrap'"
             alt="">
        <img src="@/assets/home/分析报告_active.png"
             v-else
             alt="">
        <span :class="{'active':path == '/analyze_report_wrap'}">
          分析报告
        </span>
      </div>
      <div class="item"
           @click="toPage('/course')">
        <img src="@/assets/home/优质教程.png"
             v-if="path != '/course'&& path!='/lecture_free'"
             alt="">
        <img src="@/assets/home/优质教程_active.png"
             v-else
             alt="">
        <span :class="{'active':path == '/course'}">
          优质课程
        </span>
      </div>
      <div class="item"
           @click="toPage('/personalCenter')">
        <img src="@/assets/home/个人中心.png"
             v-if="path != '/personalCenter'"
             alt="">
        <img src="@/assets/home/个人中心_active.png"
             v-else
             alt="">
        <span :class="{'active':path == '/personalCenter'}">
          个人中心
        </span>
      </div>
    </div>
    <div class="grid-container">

      <div class="lefebox"
           v-for="(subject, index) in subjects"
           :key="index"
           @click="toAnswer(subject.subject_id)">
        <div class="subjectbox"
             :style="{ backgroundColor: colors[subject.subject_name] }">
          <div class="subject">
            {{ subject.subject_name }}
          </div>
          <div class="ranking">
            本周排名 {{rankInfo[index].rank}}
          </div>
          <div class="training">
            训练次数 {{rankInfo[index].amount ? rankInfo[index].amount:0}}
          </div>
          <img class="subimg"
               src="@/assets/bg@2x.png"
               alt="">
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { getSubjectName, getRank } from '@/api/Aaswer.js'
export default {
  data () {
    return {
      path: '',
      showStudyInfo: false,
      userInfo: {},
      subjects: [],
      colors: {
        '语文': '#7e9cf7',
        '数学': '#1bbfb9',
        '理科数学': '#1bbfb4',
        '文科数学': '#5ad0e1',
        '英语': '#fad375',
        '物理': '#feb77c',
        '化学': '#5baae8',
        '生物学': '#94b487',
        '历史': '#ad96e7',
        '思想政治': '#f07c69',
        '地理': '#d6ce9f',
      },
      rankInfo: []
    };
  },
  async created () {
    let userinfo = JSON.parse(window.localStorage.getItem('userInfo'))
    let userId = Number(userinfo.user_id)
    const { data } = await getRank({
      uid: userId,
    })
    this.rankInfo = data
    console.log('ssdddddd', this.rankInfo)
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        // console.log(val);
        this.path = val.path
      },
      // 深度观察监听
      deep: true
    },
  },
  mounted () {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    this.path = this.$route.path
    // 监听浏览器缓存是否修改，主要是userInfo，修改了则页面刷新，解决重新登录后vuex缓存没变
    window.addEventListener('storage', this.watchStorge)
    this.getsubject()
  },
  destroyed () {
    window.removeEventListener('storage', this.watchStorge)
  },
  methods: {
    toPage (path) {
      this.path = path
      this.$router.push(path)
    },
    watchStorge (event) {
      if (event.key == 'userInfo') {
        this.$store.commit('CLEAR_SUBJECT')
        // this.$router.go(0)
      }
    },
    toAnswer (subject_id) {
      this.$router.push({
        path: '/Answer',
        query: {
          subject_id: subject_id,
        }
      })
    },
    async getsubject () {
      const { data } = await getSubjectName()
      console.log(data.list, "科目");
      this.subjects = data.list.map(item => {
        return {
          subject_id: item.subject_id,
          subject_name: item.subject_name,
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.home_warp {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: #f6f6f6;

  .left_controller {
    z-index: 1999;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 5.21vw;
    min-width: 100rem;
    height: 100vh;
    background: #ffffff;
    box-shadow: 0rem 2rem 8rem 2rem rgba(0, 0, 0, 0.1);
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 45rem;
      font-weight: 500;
      color: #888888;
      font-size: 20rem;
      cursor: pointer;
      img {
        width: 44rem;
        height: 44rem;
      }
    }
  }
  .grid-container {
    cursor: pointer;
    // width: 1920rem;
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20rem;
    justify-items: center; // 居中对齐每个单元格的内容
    align-items: center; // 居中对齐每个单元格的内容
    .lefebox {
      // margin-left: 100rem;
      .subjectbox {
        width: 405rem;
        height: 248rem;
        box-shadow: 0rem 4rem 8rem 0rem rgba(0, 0, 0, 0.1);
        border-radius: 24rem 24rem 24rem 24rem;
        opacity: 1;
        position: relative;
        .subject {
          position: absolute;
          left: 24rem;
          top: 24rem;
          font-size: 32rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 48rem;
        }
        .ranking {
          position: absolute;
          left: 24rem;
          top: 136rem;
          font-size: 24rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 36rem;
        }
        .training {
          position: absolute;
          left: 24rem;
          top: 188rem;
          font-size: 24rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 36rem;
        }
        .subimg {
          width: 405rem;
          height: 248rem;
          border-radius: 24rem 24rem 24rem 24rem;
          opacity: 1;
        }
      }
    }
  }

  .right_view {
    flex: 1;
    width: calc(100vw - 100rem);
    height: 100vh;
    overflow-y: auto;
    background: #f6f6f6;
  }
}
.active {
  color: #2196f3 !important;
}
.chartsDialog {
  .title {
    font-size: 26rem;
  }
  .charts_content_wrap {
    position: relative;
  }
  .icon {
    position: absolute;
    top: -40rem;
    right: -4rem;
    width: 50rem;
    height: 50rem;
    cursor: pointer;
    img {
      width: 130%;
      height: 130%;
    }
  }
  position: fixed;
  background: #fff;
  box-shadow: 6px 0px 61px 1px rgb(117 195 255 / 60%);
  z-index: 99;
  left: 132rem;
  top: 30rem;
  padding: 20rem 0rem 0rem;
  border-radius: 19rem;
}
</style>